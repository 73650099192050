import {
    Box,
    Container, Divider, Grid, styled, Typography,
} from '@mui/material';
import {
    Download, InsertEmoticon as InsertEmoticonIcon,
    SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
    CancelPresentation as CancelPresentationIcon,

    AccountBalanceWalletOutlined as AccountBalanceWalletOutlinedIcon,
} from '@mui/icons-material';
import React from 'react';

import config from '../../../config/config';
import Anchor from '../foundation/Anchor';
import CustomBR from '../foundation/CustomBR';
import Page from '../layout/base.page';
import { CenteredFlex, Flexbox, VCenteredFlex } from '../foundation/Flex';
import {
    BGContainer, ShadowBGContainer, YellowTypography,
} from '../foundation/Containers';
import TawkTo from '../components/TawkTo';
import { WatchCVPageProps } from '../../../contractTypes/pages/WatchCVPage.types';
import Tooltip from '../foundation/Tooltip';
import Button from '../foundation/Button';
import { usePromotions } from '../../api/promotions.api';
import { promotionTypes } from '../../../common/promotion_type.enum';
import BackdropSpinner from '../foundation/BackdropSpinner';

const AccountStats = styled(VCenteredFlex)`
    justify-content: space-evenly;
    color: white;
    background: ${props => props.theme.palette.secondary.main};
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
`;

export default class WatchCVPage extends React.Component<WatchCVPageProps, { iframeLoaded: boolean }> {
    constructor(props) {
        super(props);
        this.iframeRef = React.createRef();
        this.state = {
            iframeLoaded: false,
        };
    }

    iframeRef: React.RefObject<HTMLIFrameElement>;

    iframeTimeoutId: number;

    iframeTestLoadedIntervalId: number;

    iframeLoaded = () => {
        this.setState({ iframeLoaded: true });
        clearInterval(this.iframeTimeoutId);
        clearInterval(this.iframeTestLoadedIntervalId);
    };

    getDownloadLink = () => `${config.baseUrl}employer/cv/${this.props.userAppliedId}/job/${this.props.jobAppliedId}?safeid=${this.props.safeid}`;

    updateIframeSrc = () => {
        if (!this.props.isAllowedToWatchUserDetails) return;

        if (this.iframeRef?.current) {
            this.iframeRef.current.src = `https://docs.google.com/gview?url=${this.getDownloadLink()}&embedded=true`;
        }
    };

    componentDidMount() {
        if (this.props.isAllowedToWatchUserDetails) {
            this.iframeTimeoutId = window.setInterval(
                this.updateIframeSrc, 1000 * 3,
            );
        }

        this.iframeTestLoadedIntervalId = window.setInterval(() => {
            const iframeDoc = this.iframeRef?.current?.contentDocument || this.iframeRef?.current?.contentWindow.document;
            if (iframeDoc && (iframeDoc.readyState === 'complete' || iframeDoc.readyState === 'interactive')) {
                this.iframeLoaded();
            }
        }, 1000);
    }

    getCV() {
        if (this.props.hasCV) {
            if (this.props.isImage && this.props.isAllowedToWatchUserDetails) {
                return (
                    <img alt="קורות חיים" ref="img" src={`${this.getDownloadLink()}&iframe=true`} style={{ width: '100%' }} />
                );
            }
            return <Box position="relative">
                <BackdropSpinner open={!this.state.iframeLoaded} style={{ position: 'absolute' }} />
                <iframe ref={this.iframeRef} title="קורות החיים" onLoad={this.iframeLoaded}
                    onError={this.updateIframeSrc}
                    style={{ width: '100%', height: '700px', border: 0 }}
                    src={(!this.props.isAllowedToWatchUserDetails)
                        ? `${this.getDownloadLink()}&iframe=true` : `https://docs.google.com/gview?url=${this.getDownloadLink()}&embedded=true`} />
            </Box>;
        }

        return '';
    }

    renderKeyValue(key, value) {
        if (value && value !== '') {
            return <Typography><b>{key} : </b>{value}</Typography>;
        }
        return '';
    }

    renderDontHaveCV() {
        if (!this.props.hasCV) {
            return <div><CustomBR /> <Typography>
                    המשתמש הגיש מועמדות למשרה זו, אך לא הגיש קובץ קורות חיים.
            </Typography></div>;
        }

        return '';
    }

    renderCandidateDetails() {
        if (!this.props.candidate) {
            return <><CustomBR /><BGContainer>
                <Typography fontWeight="bold" style={{ textDecoration: 'underline' }}>פרטי המועמד</Typography>
                <CustomBR />
                <>
                    {this.renderKeyValue('שם הפונה למשרה', this.props.firstName)}
                    {this.renderKeyValue('מספר טלפון', '*********')}
                    {this.renderKeyValue('כתובת אימייל', '*********')}
                    {this.renderKeyValue('הודעה מהמועמד', this.props.candidateMessage)}
                    {this.renderDontHaveCV()}
                    <CustomBR />
                    <Button
                        style={{ backgroundColor: '#FFC400' }}
                        variant="contained" onClick={this.onShowUserDetailsClick}>
                        <Typography color="secondary" fontWeight="bold">הצג את פרטי מועמד</Typography>
                    </Button>
                </>
            </BGContainer></>;
        }

        return <><CustomBR /><BGContainer>
            <Typography fontWeight="bold" style={{ textDecoration: 'underline' }}>פרטי המועמד</Typography>
            <CustomBR />
            {this.props.isAllowedToWatchUserDetails
                ? <>
                    {this.renderKeyValue('שם הפונה למשרה', this.props.candidate.firstname || `${this.props.candidate.lastname}` || '')}
                    {this.renderKeyValue('מספר טלפון', this.props.candidate.phone)}
                    {this.renderKeyValue('כתובת אימייל', this.props.candidate.email)}
                    {this.renderKeyValue('הודעה מהמועמד', this.props.candidateMessage)}
                    {this.renderDontHaveCV()}
                </>
                : <Typography>יש לרכוש קרדיטים על מנת לצפות בפרטי המועמד</Typography>
            }
        </BGContainer></>;
    }

    renderWantMoreCandidates() {
        return (
            <div>
                <h4>מעוניינים במועמדים איכותיים נוספים ?</h4>
                <h6 style={{ lineHeight: '1.7rem' }}>
                    בדיוק בשביל זה אנחנו פה - הצטרפו לפלטפורמת דרושים חדשנית.
                    <br />
                    לפרטים נוספים צרו איתנו קשר במייל : <a href={`mailto:${config.contact.email}`}>{config.contact.email}</a>
                </h6>
            </div>
        );
    }

    renderJobItem() {
        return <><CustomBR /><Anchor href={`/job/details/${this.props.jobAppliedId}`} blank>
                    לחץ כאן לצפייה במשרה המתאימה המופיעה באתר
        </Anchor></>;
    }

    renderDownloadCV() {
        if (!this.props.hasCV || !this.props.isAllowedToWatchUserDetails) return '';

        return <div>
            <CustomBR />
            <Anchor blank href={this.getDownloadLink()}>
                <VCenteredFlex><Download /> לחץ כאן להורדת קובץ קורות החיים </VCenteredFlex>
            </Anchor>
            <CustomBR />
        </div>;
    }

    renderApplicationResponse() {
        const { candidate, jobAppliedId, safeid } = this.props;

        if (!candidate) return '';

        return (
            <div>
                <CustomBR />
                <Typography><b>חשוב! עדכנו את המועמד בקליק</b> - לחצו על האופציה המתאימה ושלחו עדכון מיידי למועמד :</Typography>
                <CustomBR />
                <Grid container spacing={2}>
                    <Grid item sm={12} md={4}>
                        <ShadowBGContainer><CenteredFlex>
                            <Anchor blank href={`/employer/applicationResponse/${candidate.userid}/job/${jobAppliedId}?safeid=${safeid}&response=2`}>
                                <CenteredFlex><InsertEmoticonIcon />&nbsp;אני אצור קשר עם המועמד בקרוב</CenteredFlex>
                            </Anchor>
                        </CenteredFlex></ShadowBGContainer>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <ShadowBGContainer><CenteredFlex>
                            <Anchor blank href={`/employer/applicationResponse/${candidate.userid}/job/${jobAppliedId}?safeid=${safeid}&response=3`}>
                                <CenteredFlex><SentimentVeryDissatisfiedIcon />&nbsp;לצערי, המועמד אינו מתאים למשרה</CenteredFlex>
                            </Anchor>
                        </CenteredFlex></ShadowBGContainer>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <ShadowBGContainer><CenteredFlex>
                            <Anchor blank href={`/employer/applicationResponse/${candidate.userid}/job/${jobAppliedId}?safeid=${safeid}&response=4`}>
                                <CenteredFlex><CancelPresentationIcon />&nbsp;המשרה אוישה</CenteredFlex>
                            </Anchor>
                        </CenteredFlex></ShadowBGContainer>
                    </Grid>
                </Grid>
            </div>
        );
    }

    renderCreditBank() {
        return (
            <AccountStats>
                <Flexbox alignItems="center" justifyContent="space-between" width="100%">
                    <CenteredFlex>
                        <AccountBalanceWalletOutlinedIcon />
                        <Tooltip title="כמות הקרדיטים ברשותך לצפייה בקורות חיים של מועמדים. קרדיט אחד שווה לצפייה במועמד אחד.">
                            <Typography color="white">&nbsp;יתרת קרדיטים :&nbsp;&nbsp;&nbsp;</Typography>
                        </Tooltip>
                        <div>
                            <YellowTypography>{this.props.totalUserCredits || 0}</YellowTypography>
                        </div>
                    </CenteredFlex>
                    <CenteredFlex>
                        <Button color="primary" variant="contained">
                            <Anchor blank href="/user/buyCredits">
                                <Typography color="white">רכישת קרדיטים</Typography>
                            </Anchor>
                        </Button>
                    </CenteredFlex>
                </Flexbox>
                <CustomBR />
            </AccountStats>
        );
    }

    onShowUserDetailsClick = () => {
        if (this.props.isAllowedToWatchUserDetails) return;

        if (!this.props.totalUserCredits || this.props.totalUserCredits < 1) {
            window.showGlobalModal(
                'גישה לפרטי הקשר של המועמד/ת',
                `לצפייה בפרטי הקשר של המועמד/ת ${this.props.firstName}, יש לרכוש קרדיטים. השקעה בקרדיט אחד בלבד תאפשר לך ליצור קשר ישיר ולמצוא את הכישרון הבא לעסק שלך!`,
                {
                    footer: {
                        rightBtnClick: () => window.hideGlobalModal(),
                        rightBtnText: 'ביטול',
                        leftBtnClick: () => window.open('/user/buyCredits', '_blank'),
                        leftBtnText: 'רכישת קרדיטים',
                    },
                },

            );
            return;
        }

        window.showGlobalModal('אישור צפייה בפרטי מועמד', 'אתה עומד לצפות בפרטי מועמד בעלות של 1 קרדיט. האם אתה בטוח שברצונך להמשיך?', {
            footer: {
                rightBtnClick: () => window.hideGlobalModal(),
                rightBtnText: 'ביטול',
                leftBtnClick: async () => {
                    await usePromotions({
                        type: promotionTypes.credits,
                        amount: 1,
                        userid: this.props.userAppliedId,
                        jobid: this.props.jobAppliedId,
                    });

                    window.location.reload();
                },
                leftBtnText: 'אישור',
            },
        });
    };

    render() {
        return (
            <Page pageHeaderTitle="Mploy - צפייה במועמדות למשרה" url={this.props.url} user={this.props.user}>
                <TawkTo />
                <Container>
                    <CustomBR />
                    <CenteredFlex>
                        <Typography color="secondary" fontWeight="bold" variant="h4">התקבלה מועמדות חדשה&nbsp;</Typography>
                        <Anchor color="secondary" fontWeight="bold" variant="h4" href={`/job/details/${this.props.jobAppliedId}`} blank style={{ textDecoration: 'underline' }}>
                            <>למשרה - {this.props.job.title} {this.props.job.employerJobid}</>
                        </Anchor>
                    </CenteredFlex>
                    {/* {this.renderJobItem()} */}
                    <CustomBR />
                    {this.renderCreditBank()}
                    {this.renderApplicationResponse()}
                    {this.renderCandidateDetails()}
                    {this.renderDownloadCV()}
                    <CustomBR />
                    <Divider />
                    <CustomBR />
                    {!this.props.isAllowedToWatchUserDetails && <BGContainer>
                        <Flexbox>
                            <Typography color="secondary" fontWeight="bold" variant="h5">
                                צפייה בפרטי הקשר של {this.props.firstName || 'המועמד'},
                                &nbsp;כרוכה בעלות של 1 קרדיט -&nbsp;
                            </Typography>
                            <Button
                                style={{ backgroundColor: '#FFC400' }}
                                variant="contained" onClick={this.onShowUserDetailsClick}>
                                <Typography color="secondary" fontWeight="bold">הצג את פרטי מועמד</Typography>
                            </Button>
                        </Flexbox>
                    </BGContainer>}
                    <div style={{ textAlign: 'center' }}>
                        <CustomBR />
                        {this.props.isAllowedToWatchUserDetails ? <Typography color="secondary" fontWeight="bold" variant="h5">קורות חיים</Typography>
                            : <Flexbox>
                                <Typography color="secondary" fontWeight="bold" variant="h5">קורות חיים</Typography>
                                <Typography lineHeight={2.5} color="secondary" fontWeight="bold" variant="body2">&nbsp;(פורמט גולמי)</Typography>
                            </Flexbox>}
                        <CustomBR />
                        {this.getCV()}
                    </div>
                    <CustomBR />
                    <CustomBR />
                </Container>
            </Page>
        );
    }
}
